<template>
    <b-card title="Add Recoil">
      <b-col cols="4">
        <b-button variant="primary" :to="{ name: 'recoil' }" size="sm">
          <feather-icon size="1x" icon="ArrowLeftIcon" /> Back to Recoil
        </b-button>
      </b-col>
      <br />
  
      <b-col cols="12">
        <br />
  
        <h4>Item</h4>
  
        <b-row>
          <b-col>
            <b-table :items="form.item" :fields="fieldsItem" small head-variant="dark" responsive stacked="md">
              <template v-slot:cell(itemName)="row">
              <div style="width: 210px;">
                <v-select
                  v-model="form.item[row.index].detail"
                  :options="itemsStore"
                  label="name"
                  :clearable="false"/>
              </div>
              </template>
              <template v-slot:cell(itemQuantity)="row">
              <b-form-input
                type="number"
                step = "1"
                min= 0
                v-model="form.item[row.index].quantity"
              ></b-form-input>
            </template>
              <template v-slot:cell(itemNote)="row">
                <b-form-input
                  v-model="form.item[row.index].note"
                ></b-form-input>
              </template>
              <template v-slot:cell(itemDrum)="row">
              <div style="width: 210px;">
                <v-select
                  v-model="form.item[row.index].drum"
                  :options="drumsStore"
                  label="name"
                  :clearable="false"/>
              </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button @click="form.item.splice(row.index, 1)" variant="danger" size="sm"
                  ><feather-icon size="1x" icon="XIcon" /></b-button
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              size="sm"
              variant="success"
              @click="
                form.item.push(
                  {
                    detail: '',
                    name:'',
                    quantity: '',
                    note:'',
                    drum:'',
                  }
                )
              "
              class="mt-2 float-right"
              >Add Item</b-button
            >
          </b-col>
        </b-row>
      </b-col>
  
      <br />
  
      <b-col cols="12">
        <b-button
          variant="primary"
          class="float-right"
          @click="addRecoil"
        >
          Save
        </b-button>
      </b-col>
    </b-card>
  </template>
  
  <script>
  import vSelect from 'vue-select'
  
  export default {
    components: {
      vSelect
    },
    data() {
      return {
        form: {
          orderNumber: '',
          requestedBy: {
            name: '',
            department: ''
          },
          item: [],
          grandTotal:0,
          type: 'Recoil',
          props: {}
        },
        items: [],
        fieldsItem: [
          { key: "itemName", label: "Name" },
          { key: "itemQuantity", label: "Quantity" },
          { key: "itemNote", label: "Note" },
          { key: "itemDrum", label: "Drum" },
          { key: "actions" },
        ],
        departmentList: [
          "HR",
          "Marketing",
          "Accounting",
          "Production",
          "Warehouse",
          "QA",
          "Maintenance",
          "IT",
        ],
      };
    },
  
    methods: {
      clearForm (){
        this.form = {}
      },
      // add quotation function
      async addRecoil() {
        var formTemp = {...this.form};
        formTemp.props.app = this.$session.get('phibase-app');
        
        /* all item mapping is processed in order/addOrder store action
        formTemp.item.map( el=> {
          el.detail = el.detail.id
  
        })
        */
        this.$store
          .dispatch("order/addOrder", formTemp)
          .then(() => {
            this.$bvToast.toast("Successfully Add Order", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.clearForm()
            this.$router.push('/recoil')
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.error, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
      },
    },
  
    created() {
      document.title = "Add Recoil | Tai Sin";
    },
    computed: {    
      itemsStore() {
        return this.$store.getters["item/getItem"] ? this.$store.getters["item/getItem"].filter(comp => comp.name.toUpperCase() !== comp.props.id) : [];
      },
      drumsStore(){
        return this.$store.getters["bom/getBOM"] ? this.$store.getters["bom/getBOM"].filter(comp => comp.props.type === "Mama Drum" ) :[];
      }
    },
  
    mounted() {
      this.$store
        .dispatch("item/fetchItem")
        .catch((err) => console.log(err));
      this.$store
        .dispatch("user/fetchUser")
        .catch((err) => console.log(err));
    this.$store
        .dispatch("bom/getItems")
        .catch((err) => console.log(err));    
    },
  };
  </script>
  
  <style scoped>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      -webkit-appearance: textfield;
      appearance: textfield;
  }</style>